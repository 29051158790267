/* eslint "sort-keys": ["warn", "asc", {"caseSensitive": false, "natural": false, "minKeys": 2}] */
export default {
  AllTransactions: {
    customerDescriptors: 'Customer descriptors',
    customerFulfillment: 'Customer fulfillment method',
    customerName: 'Customer name',
    customerPhoneNumber: 'Customer phone number',
    customerTitle: 'Customer',
    deliveryZone: 'Delivery zone',
    fontSize: 'Font size for menu items',
    fulfillmentTitle: 'Fulfillment',
    homePageDescription: 'Prints all items on the order',
    homePageTitle: 'All Transaction Receipt',
    largeButton: 'Large',
    menuItemsTitle: 'Menu Items',
    orderNumber: 'Order number',
    orderNumberValue: 'Prints by default',
    orderTakingTitle: 'Order-Taking',
    registerNumber: 'Register number',
    smallButton: 'Small',
    teamMemberName: 'Team member name',
  },
  AppRoutes: {
    baseName: 'ViewPoint Home',
    createProfile: 'Create profile',
    mainMenu: 'Main Menu',
  },
  Bump: {
    bumpsHeaderBeginning: 'Choose ',
    bumpsHeaderBold: 'at least one ',
    bumpsHeaderEnd:
      'way to remove orders on VP screens that will have this profile enabled.',
    choice1: 'Remove order when bumped by',
    // choice2: 'Remove order when it is bagged',
    // choice3: 'Remove order when it is completed',
    // choice4: 'Remove order when it is cancelled',
    // choice5: 'Remove order when it is removed',
    viewMoreProfilesButton: 'View more profiles',
  },
  CopyProfile: {
    // alwaysShowLabel:'Always Show',
    // neverShowLabel:'Never Show',
    // whenModified:'When Modified',
    copyConfirmLabel: 'Create Profile',
    copyLabel: 'Name',
    copyPlaceholder: 'Enter a name',
    descriptionBeginning: 'Are you sure you want to duplicate',
    descriptionEnd:
      '? Settings from the duplicated VP Profile transfer to this new VP Profile.',
    duplicateLabel: 'Duplicate',
    duplicateProfile: 'Duplicate Profile',
  },
  CreateProfile: {
    bump: 'Bump Component',
    continue: 'Continue',
    createProfileLabel: 'Create Profile',
    destinations: 'Destinations Component',
    layout: 'Layout Component',
    menuItems: 'Menu Items Compoent',
    review: 'Review Compoent',
    role: 'Role Component',
    stepperSubtitle: 'Complete the following steps to create a new profile',
  },
  DeleteProfileLabels: {
    CancelButtonLabel: 'Cancel',
    DeleteButtonLabel: 'Delete',
    Header: 'Delete Profile',
    Subtitle:
      'When a profile is deleted, you are unable to make edits. Are you sure you want to delete this profile',
  },
  DestinationLabel: {
    fulfillmentHeaderBeginning: 'Choose ',
    fulfillmentHeaderBold: 'at least one order fulfillment ',
    fulfillmentHeaderEnd: 'option to route to this profile',
    originHeaderBeginning: 'Choose ',
    originHeaderBold: 'at least one origin ',
    originHeaderEnd: 'to route to this profile',
  },
  EditProfile: {
    Bumps: 'Bumps',
    EditButtonLabel: 'Edit',
    itemRouting: 'Item Routing',
    Label: 'Edit Profile',
    Menu: 'Menu Items',
    OrderFulfil: 'Order Origin & Fulfillment',
    PermanentlyDeleteButtonLabel: 'Permanently Delete',
    ReceiptPrinting: 'Receipt Printing',
    RenameButtonLabel: 'Rename',
    Role: 'Role',
    Subtitle: 'Edit configurations to customize VP screens for this profile',
  },
  EditRoleModal: {
    cancel: 'Cancel',
    changeRoleButton: 'Change Role',
    editModalDescription:
      'Are you sure you want to change the role for this profile? This will change the menu items that are currently selected for this profile, Drinks - Test.',
    editModalHeader: 'Change Role',
  },
  ErrorMessage: {
    BumpsProfilesOrStatusesHeader: 'No Bumps Profiles or Statuses',
    BumpsProfilesOrStatusesSubtitle:
      'There are currently no bump profiles or statuses options selected. To continue, you must choose at least one from category.',
    ItemRoutingHeader: 'No Items Selected',
    ItemRoutingSubtitle:
      'There are currently no selections in the items. To continue, you must choose at least one.',
    MenuItemsHeader: 'No Menu Items Selected',
    MenuItemsSubtitle:
      'There are currently no selections in the menu items. To continue, you must choose at least one.',

    OrderOriginAndFulfillmentSubtitle:
      'There are currently no order origins or fulfillment options selected. To continue, you must choose at least one from category.',
    OrderOriginOrFulfillmentHeader: 'No Origin Order or Fulfillment',
    OrderOriginOrFulfillmentSubtitle:
      'There are currently no selections in either the origin or fulfillment category. To continue, you must choose at least one from category.',
    SaveVpProfileEmptyErrorMessage:
      'There was an error saving empty profile. Please try again.',
    SaveVpProfileErrorMessage:
      'There was an error saving this profile. Please try again.',
  },
  Home: {
    homeTitle: 'ViewPoint',
    Subtitle:
      'Manage profiles and use pre-defined templates to customize store experiences',
    title: 'VP Profiles',
  },
  ItemLabels: {
    customerDescriptors: 'Customer descriptors',
    customerFulfillment: 'Customer fulfillment method',
    customerName: 'Customer name',
    customerPhoneNumber: 'Customer phone number',
    customerTitle: 'Customer',
    deliveryZone: 'Delivery zone',
    fontSize: 'Font size for menu items',
    fulfillmentTitle: 'Fulfillment',
    homePageDescription:
      'Prints individual item receipts for items denoted as specials',
    homePageTitle: 'Items Label (Specials) Receipt',
    largeButton: 'Large',
    menuItemsTitle: 'Menu Items',
    orderNumber: 'Order number',
    orderNumberValue: 'Prints by default',
    orderTakingTitle: 'Order-Taking',
    printRoutedItems: 'Print receipts for all routed items',
    registerNumber: 'Register number',
    routedItemDescription:
      'When turned on, an item label will print for all items that are routed to this profile',
    smallButton: 'Small',
    teamMemberName: 'Team member name',
  },
  ItemLevelRelease: {
    ItemLevelReleaseOffMessage: 'Item-level release is turned off',
    ItemLevelReleaseOnMessage: 'Item-level release is turned on',

    itemReleaseDiscription:
      'When turned off, all menu items will be released to the kitchen at the same time.',

    mainTitle: 'Item-Level Release',
    modalCancel: 'Cancel',
    modalConfirm: 'Confirm',
    modalDisableDescription:
      'Are you sure you want to turn off item-level release? This will cause menu items to be released to the kitchen at the same time.',
    modalDisableHeader: 'Turn off item-level release',
    modalEnableDescription:
      'Are you sure you want to turn on item-level release? This will cause menu items to be released to the kitchen at different times.',
    modalEnableHeader: 'Turn on item-level release',

    subTitle:
      'Configure when Chick-fil-A and Little Blue Menu items are released to the kitchen from preparation',
    title: 'Item-level release',
  },
  ItemRouting: {
    alwaysShowLabel: 'Always Show',
    confimationModalConfirmButton: 'Confirm',
    confirmationModalBody:
      'This will alter the display for all items in this item group.',
    confirmationModalCancelButton: 'Cancel',
    confirmationModalHeader: 'Change routing for',
    displayOptionLabel: 'Display Options',
    editPageDescription:
      'Customize which Item Groups and menu items display on VP devices for this profile',
    ItemLabel: 'Items',
    mixedLabel: 'Mixed',
    neverShowLabel: 'Never Show',
    noResultFoundLabel: 'No Result Found',
    selectedItemLabel: 'selected',
    whenModifiedLabel: 'When Modified',
  },
  Layout: {
    customLabel: 'Custom View',
    gridLabel: 'Grid View',
    headerLabel:
      'Choose a UI layout to customize the appearance of VP screen(s)',
  },
  MenuCategoryDisplay: {
    combos: 'Combos',
    homePageDescription:
      'Change the order of categories and menu items on ViewPoint devices',
    homePageTitle: 'Menu Category & Item Sorting',
  },
  MenuItems: {
    allItemsModalCloseButton: 'Close',
    allMenuDeviceGroupTitle: 'Assigned Device Group',
    allMenuItemsButtonTitle: 'View all menu items',
    allMenuItemsCopyButtonTitle: 'Copy results to clipboard',
    allMenuItemsModalTitle: 'All Menu Items',
    allMenuItemsSortButtonTitle: 'Items',
    cancelButton: 'Cancel',
    closeButton: 'Close',
    copyResultClipboard: 'Copy results to clipbord',
    editButton: 'Edit',
    editedText: 'Edited',
    editItemButton: 'Edit items',
    importAddButton: 'Import',
    importButton: 'Import RMIDS',
    importModalHeader: 'Menu Item RMIDs',
    importModalWarning:
      'Note: Importing the following list will overwrite any previous selections',
    itemsText: 'items',
    menuItemNameText: 'Edit Menu Items for',
    menuTitle:
      'Current selections are based on the profile’s assigned role. Make customizations to all groups and menu items',
    viewAllButton: 'View all menu items',
  },
  MenuSortingItem: {
    bottomButton: 'Move to Bottom',
    homeDescription:
      'Drag and drop or use the menu to reorder menu items within this category',
    topButton: 'Move to Top',
  },
  PreviewButton: {
    preview: 'Preview',
  },
  ProfileNameInput: {
    cancelButton: 'Cancel',
    createConfirmLabel: 'Continue',
    createHeader: 'Create profile',
    createLabel: 'Name',
    createPlaceholder: 'Enter a name',
    createSubtitle:
      'First, choose a name for the new profile. You can change this name later.',
    editConfirmLabel: 'Continue',
    editHeader: 'Rename profile',
    editLabel: 'Name',
    editPlaceholder: '',
    editSubtitle: '',
    renameButton: 'Rename',
  },
  ReceiptPrinting: {
    allTransactionsReceiptType: 'All transactions',
    allTransactionsReceiptValue: 'AllTransactionsReceipt',
    customerReceiptDescriptionText:
      'A TM88 printer must be installed for this receipt to print',
    customerReceiptType: 'Customer Receipt',
    customerReceiptValue: 'customerReceipt',
    editPageDescription:
      'Customize which receipt prints for all orders on VP devices with this profile',
    fontSizeForMenuItems: 'Font size for menu items',
    itemLabelsReceiptAllRoutedItemsTooltip:
      'When turned on, an item label will print for all items that are routed to this profile',
    itemLabelsReceiptType: 'Items label (Specials)',
    large: 'Large',
    medium: 'Medium',
    printAreaForBarcode: 'Print area for barcode',
    printCustomerReceiptDescription:
      'When turned on, a customer receipt will print when an order is bumped on VP devices within this profile',
    printCustomerReceiptTitle: 'Print customer receipt',
    printFulfillmentType: 'Print fulfillment type (e.g., dine in)',
    printGuestDescriptor: 'Print guest descriptor',
    printGuestName: 'Print guest name',
    printGuestPhoneNumber: 'Print guest phone number',
    printItemLabelForRoutedItems: 'Print item label for all routed items',
    printNoItemsAlert: 'Print "no other items" alert',
    printOnBumpDescription:
      'When turned on, this profile will print receipt(s) when you press the bump button',
    printOnBumpTitle: 'Print receipt on bump',
    printOrderNumber: 'Print order number',
    printReceiptDescription:
      'When turned on, this profile can print receipt(s) when you press the print button',
    printReceiptTitle: 'Enable receipt printing',
    printRegisterNumber: 'Print register number',
    printServiceReceiptTitle: 'Print service receipt',
    printTeamMemberName: 'Print team member name',
    reviewDisableText: 'Receipt printing disabled',
    reviewPrintOnBumpText: 'Print receipt on bump',
    reviewPrintReceiptText: 'Enable receipt printing',
    routedItemsReceiptNoOtherItemsTooltip:
      'When turned on, a "no other items" text label will print on routed items receipts for this profile',
    routedItemsReceiptType: 'Routed items',
    routedItemsReceiptValue: 'RoutedItemsReceipt',
    selectReceiptTypeErrorText: 'Select a type of receipt',
    small: 'Small',
    specialsReceiptValue: 'SpecialsReceipt',
    typeReceiptTitle: 'Type of receipt',
    typeServiceReceiptDescription:
      'Select a type of receipt to print for all orders',
  },
  Review: {
    bumpsLabel: 'Bumps',
    destinationsLabel: 'Origin & Fulfillment',
    editLabel: 'Edit',
    itemRoutingLabel: 'Item Routing',
    itemRoutingLableAlwaysShow: 'Always Show',
    itemRoutingLablewhenModifiedLabel: 'When Modified',

    layoutLabel: 'Layout',
    menuItemsLabel: 'Menu Items',
    receiptPrintingLabel: 'Receipt Printing',
    roleLabel: 'Role',
  },
  RoleLabel: {
    roleHeaderBeginning: 'Choose ',
    roleHeaderBold: 'one ',
    roleHeaderEnd: 'role for your profile',
  },
  RoutedItems: {
    homePageDescription:
      'Prints only items that have been routed to the profile',
    homePageTitle: 'Routed Items Receipt',
    menuItemsPrintNo: 'Print ’no other items’ alert',
    menuItemsPrintNoDescription:
      'When turned on, a ’no other items’ text label will print on routed items receipts for this profile',
    menuItemsPrintOnly: 'Print only if specials / modified exist in profile',
  },
  ServiceReceipts: {
    allTransactionsLabel: 'Prints all items on the order',
    customerCustomerName: 'Customer name',
    customerCustomerPhoneNumber: 'Customer phone number',
    customerTitle: 'Customer',
    fulfillmentCustomerDescriptors: 'Customer Descriptors',
    fulfillmentCustomerFulfillmentMethod: 'Customer fulfillment method',
    fulfillmentDeliveryZone: 'Delivery zone',
    fulfillmentTitle: 'Fulfillment',
    homePageDescription:
      'Customize the information that displays on different service receipts',
    homePageTitle: 'Service Receipts',
    itemsLabelLabel:
      'Prints individual item receipts for items denoted as Specials',
    menuItemsFontSize: 'Font size for menu items',
    menuItemsFontSizeLargeButton: 'Large',
    menuItemsFontSizeSmallButton: 'Small',
    menuItemsTitle: 'Menu Items',
    orderTakingOrderNumber: 'Order number',
    orderTakingRegisterNumber: 'Register number',
    orderTakingTeamMemberName: 'Team member name',
    orderTakingTitle: 'Order-Taking',
    printsByDefault: 'Prints by default',
    routedItemsLabel: 'Prints only items that have been routed to the profile',
  },
  SpecialItems: {
    lastUpdatedText: '{{date}} at {{time}} by {{name}}',
    nameHeader: 'Items',
    noResultsFound: 'No results found',
    searchLabel: 'Search Items',
    specialItemsDescription: 'Modify which items are considered Special Items',
    specialItemsHeader: 'Special Items',
    specialLabel: 'Is Special',
    specialsHeader: 'Special Status',
  },
  Stepper: {
    backButton: 'Back',
    bumpsLabel: 'Bumps',
    destinationsLabel: 'Origin & Fulfillment',
    ItemRoutingLabel: 'Item Routing',

    layoutLabel: 'Layout',

    menuitemsLabel: 'Menu Items',
    nextButton: 'Next',
    receiptPrintingLabel: 'Receipt Printing',
    reviewLabel: 'Review',
    rolelabel: 'Role',
    submitButton: 'Submit',
  },
  Venues: {
    activeVenuesLabel: 'Active Venues',
    addVenueButton: 'Add a Venue',
    archivedVenuesLabel: 'Archived Venues',
    createModalCancelButton: 'Cancel',
    createModalSubmitButton: 'Create Venue',
    detailsActivateButton: 'Activate this Venue',
    detailsArchiveButton: 'Archive this Venue',
    detailsDuplicateButton: 'Duplicate this Venue',
    detailsSaveButton: 'Save',
    emptyActiveVenuesLabel: 'No active venues',
    emptyArchivedVenuesLabel: 'No archived venues',
    idLabel: '({{storeNumber}}:{{venueId}})',
    popoverMenuActivateButton: 'Activate',
    popoverMenuArchiveButton: 'Archive',
    popoverMenuDeleteButton: 'Permanently Delete',
    popoverMenuDuplicateButton: 'Duplicate',
    popoverMenuEditButton: 'View/Edit Venue',
    popoverMenuResendButton: 'Resend Venue Data',
    promptModalCancelButton: 'Cancel',
    venueChangeButton: 'Change',
    venueChangeLabel: 'Venue: {{name}}',
    venueDetailActiveLabel: 'Status: Active',
    venueDetailArchivedLabel: 'Status: Archived',
    venueModalCancelButton: 'Cancel',
    venueModalHeader: 'Select a venue',
    venueModalSelectLabel: 'Venue',
    venueModalSubmitButton: 'Select',
  },
};
