enum NODE_ENV {
  DEVELOPMENT = 'development',
  TEST = 'test',
  PRODUCTION = 'production',
}

enum REACT_APP_ENVIRONMENT {
  DEV = 'dev',
  TEST = 'test',
  STAGE = 'stage',
  PROD = 'prod',
}

const AppConstants = {
  NODE_ENV,
  REACT_APP_ENVIRONMENT,
} as const;

export default AppConstants;
