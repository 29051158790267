import { z } from 'zod';

const coordinatesSchema = z.object({
  lat: z.number(),
  lng: z.number(),
});

export type Coordinates = z.infer<typeof coordinatesSchema>;

export const locationCoordinatesResponseSchema = z.object({
  max: z.array(coordinatesSchema),
  current: z.array(coordinatesSchema),
  staged: z.array(coordinatesSchema),
  onBoarded: z.boolean(),
});

type LocationCoordinates = z.infer<typeof locationCoordinatesResponseSchema>;

export default LocationCoordinates;
